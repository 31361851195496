<template>
  <el-form @submit.native.prevent ref="form" :model="form" :rules="rules" label-width="125px">
    <el-row>
      <el-col :span="22">
        <el-form-item label="商品图片">
          <uploadFileOnly :imageUrl.sync="form.imageDeatilUrl" :isDel.sync="editShow" />
        </el-form-item>
      </el-col>
      <el-col :span="22">
        <el-form-item label="商品轮播图片">
          <el-upload name="mfile" :headers="myHeaders" :action="uploadFileUrl" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" multiple>
            <div class="uploader-btn">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </div>
            <span style="color:red">*支持JPG、JPEG、PNG、SVG格式，最大5M</span>
          </el-upload>
          <div v-if="form.imageUrl && form.imageUrl.length>0" class="img-uploader">
            <div v-for="(item,index) in form.imageUrl" :key="index" class="img-item">
              <img :src="item" class="avatar">
              <i style="margin:0 10px 0;font-size:16px;color:red;cursor: pointer;" @click="deleteImg(index)" class="el-icon-close"></i>
            </div>

          </div>
          <!-- <uploadFileMoreImg :imageUrl="form.imageUrl" :isDel.sync="editShow" /> -->
        </el-form-item>
      </el-col>
      <el-col :span="22">
        <el-form-item label="商品名称" prop="name">
          <el-input style="width:100%;" placeholder="中英文、字母或数字组合" v-model="form.name" v-if="editShow"></el-input>
          <div class="content-text" v-else>{{form.name}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="22">
        <el-form-item label="商品标题" prop="shortDescription">
          <el-input style="width:100%;" placeholder="中英文、字母或数字组合" v-model="form.shortDescription" v-if="editShow"></el-input>
          <div class="content-text" v-else>{{form.shortDescription}}</div>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="22">
        <el-form-item label="商品说明" prop="vouchersExplain">
          <el-input style="width:100%;" placeholder="中英文、字母或数字组合，长度小于300字" type="textarea" v-model="form.vouchersExplain" maxlength="300" v-if="editShow"></el-input>
          <div class="content-text" v-else>{{form.vouchersExplain}}</div>
        </el-form-item>
      </el-col> -->
      <el-col :span="22">
        <el-form-item label="商品价格" prop="price">
          <el-input placeholder="请输入金额（支持小数点后两位）" v-model="form.price" maxlength="11" oninput="value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')" v-if="editShow"></el-input>
          <div class="content-text" v-else>{{form.price}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="商品规格" prop="goodsSize">
          <el-input placeholder="请输入商品规格" v-model="form.goodsSize" v-if="editShow"></el-input>
          <div class="content-text" v-else>{{form.goodsSize}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="商品规格单位" prop="goodsUnit">
          <el-input placeholder="请输入商品规格单位" v-model="form.goodsUnit" v-if="editShow"></el-input>
          <div class="content-text" v-else>{{form.goodsUnit}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="22">
        <el-form-item label="商品详情">
          <div id="div1" style="width:100%;height:330px;" v-if="editShow"></div>
          <div style="border:1px solid #eee;padding:10px;border-radius:10px;" class="content-text" v-else v-html="form.description"></div>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-button type="primary" v-debounce="onSubmit" v-if="!editType">立即创建</el-button>
        <el-button type="primary" v-debounce="onSubmit" v-if="editType == 2">立即保存</el-button>
        <el-button @click="dialogClose">关闭窗口</el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { baseURL } from '@/utils/http';

import { addGoosShop, updateGoosShop, getGoosDetailByWeb } from '@/api/setting';
import uploadFileOnly from '@/components/uploadFileOnly'
import uploadFileMoreImg from '@/components/uploadFileMoreImg'
import { getCookies } from '@/utils/utils'

import E from 'wangeditor'
var editor = null
export default {
  props: ['isAddDialog', 'editId', 'editType', 'copyItem'],
  components: {
    uploadFileOnly,
    uploadFileMoreImg
  },
  data() {
    var checkNumber = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/;
      if (reg.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确天数'));
      }
    };
    var checkMoney = (rule, value, callback) => {
      let reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;
      if (reg.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确金额'));
      }

      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入正确金额'));
        } else {
          callback();
        }
      }, 1000);
    };
    return {
      myHeaders: { 'x-token': getCookies('x-token') },     //设置上传文件请求头
      uploadFileUrl: baseURL + '/uploadFile/baiduUploadFile',
      fileList: [],
      form: {
        goodsSize: '',//商品规格
        goodsUnit: '',//商品规格
        description: '',//商品详情
        price: '',//商品价格
        stock: '',//库存
        imageUrl: [],//轮播图片
        imageDeatilUrl: '',//图片
        shortDescription: '',//商品标题
        name: '',//商品名称
      },
      rules: {
        vouchersPrice: [
          { required: true, message: '销售金额不可为空', trigger: 'blur' },
          { validator: checkMoney, trigger: 'blur' }
        ],
        vouchersTitle: [
          { required: true, message: '卡券标题不可为空', trigger: 'blur' },
        ],
        vouchersExplain: [
          { required: true, message: '卡券说明不可为空', trigger: 'blur' },
        ],
        vouchersNumber: [
          { required: true, message: '卡券编号不可为空', trigger: 'blur' },
        ],
        sort: [
          { required: true, message: '显示顺序不可为空', trigger: 'blur' },
        ],
        validityDay: [
          { required: true, message: '有效期不可为空', trigger: 'change' },
          { validator: checkNumber, trigger: 'blur' }
        ],
        usedArea: [
          { required: true, message: '使用范围不能为空', trigger: 'change' },
        ],
        supplierType: [
          { required: true, message: '请选择供应商', trigger: ['blur', 'change'] },
        ],//供应商
        amountPrice: [
          { required: true, message: '现金价格不可为空', trigger: 'blur' },
        ],//金额价格
        integralPrice: [
          { required: true, message: '积分价格不可为空', trigger: 'blur' },
        ],//积分价格

      },
      dataTree: [],
      cityTree: [],
      city: ''

    }
  },
  watch: {
    'form.imageUrl'(val) {
      console.log(val);

    },
    isAddDialog(n, o) {
      return
      if (!n) {
        this.form = {
          couponType: '',
          createBy: getCookies('sysId'),    //创建人id
          actualAmount: '',                 //实际金额
          deductiblePrice: '',              //抵扣金额
          vouchersPrice: '',                 //销售价格
          vouchersTitle: '',                //卡券标题
          vouchersExplain: '',              //卡券说明
          vouchersNumber: '',               //卡券编号
          vouchersPic: '',                  //卡券图片
          validityDay: '',                   //有效期
          ruleContent: '',                  //规则内容
        };
        // 销毁编辑器
        editor.destroy()
        editor = null
      }
    }
  },
  computed: {
    editShow() {
      return this.editType == 2 || !this.editType
    },
  },
  created() {
    console.log(this.editType);
    console.log(this.editId);
    this.$nextTick(() => {
      this.editType && this.editId && this.getDateil();
    })

    Object.assign(this.form, this.copyItem)
  },
  mounted() {
    if (this.editShow || this.copyItem) {
      editor = new E('#div1');
      editor.config.height = 250;
      editor.config.uploadImgServer = baseURL + '/uploadFile/uploadFileTwo';
      editor.config.uploadFileName = 'mfile'

      let headers = {
        'x-token': getCookies('x-token')
      }
      editor.config.uploadImgHeaders = headers;

      editor.config.uploadImgMaxLength = 1
      editor.create();
      editor.txt.html(this.form.ruleContent)
      console.log(this.form.ruleContent)
    }
  },
  methods: {
    deleteImg(index) {
      console.log(index);
      this.form.imageUrl.splice(index, 1)
    },
    handleAvatarSuccess(res, file) {
      console.log(file);
      console.log(res);
      console.log(this.fileList);
      if (res.code == 200) {
        this.form.imageUrl.push(res.data)
      } else {
        this.$message.error('上传错误，请稍后重试');
      }

    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/svg';
      const isLt5M = file.size / 5120 / 5120 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/JPEG/PNG/SVG 格式!');
      }
      if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      return isJPG && isLt5M;
    },
    changeCheck(checkKey) {
      // 去中文正则
      this.form[checkKey] = this.form[checkKey].replace(/[^\w\.\/]/ig, '');
    },
    getDateil() {
      getGoosDetailByWeb({ goodsId: this.editId })
        .then((response) => {
          if (response.code == 200) {
            this.form = response.data;
            this.form.imageUrl = this.form.imageUrl
            console.log(this.form.imageDeatilUrl);
            if (this.form.imageDeatilUrl.length == 0) {
              this.form.imageDeatilUrl = null
            }
            if (this.editShow) {
              editor.txt.html(this.form.description)
            }
            console.log(this.form);

          } else {
            this.$message.error({
              title: '错误',
              message: response.msg,
              duration: 1500
            });
          }

        })
    },
    dialogClose() {
      this.$emit('update:isAddDialog', false)
    },
    onSubmit() {
      var that = this;
      let form = JSON.parse(JSON.stringify(that.form));

      form.description = editor.txt.html();
      let param = {
        wlynGoods: {
          id: form.id || '',
          goodsNo: form.goodsNo || 1,
          goodsType: form.goodsType,
          name: form.name,
          price: form.price,
          stock: form.stock,
          shortDescription: form.shortDescription,
          description: form.description,
          version: form.version,
        },
        imageDeatilUrl: Array.isArray(form.imageDeatilUrl) ? form.imageDeatilUrl : [form.imageDeatilUrl],
        imageUrl: form.imageUrl,
        wlynGoodsSize: {
          goodsSize: form.goodsSize,
          goodsUnit: form.goodsUnit,
          // goodsNum: form.goodsNum,
        }
      }
      console.log(this.form.imageDeatilUrl);
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let success = res => {
            if (res.code != 200) {
              this.$message.error(res.msg || "操作失败");
              return;
            }
            this.$message.success(res.msg || '操作成功');
            that.dialogClose();
          };
          if (this.editType) {
            updateGoosShop(param).then(success)
          } else {
            addGoosShop(param).then(success)
          }
        } else {
          this.$message.error({
            title: '错误',
            message: '请检查完善输入项并重试',
            duration: 1500
          });
          return false;
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-form-item {
  text-align: left;
}
.content-text {
  letter-spacing: 1px;
  color: #333333;
  margin: 0px 10px;
  font-size: 16px;
}
.avatar {
  float: left;
  max-height: 120px;
  min-height: 80px;
  min-width: 100px;
}
.uploader-btn {
  position: relative;
  border: 1px solid #b4b4b4;
  width: 100px;
  height: 100px;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 35px;
  cursor: pointer;
}
.img-uploader {
  position: relative;
  // border: 1px solid #b4b4b4;
  /* max-width: 120px;
  max-height: 120px; */
  // border-radius: 10px;
  box-sizing: border-box;
  padding: 0;
  /* overflow: hidden; */
  display: flex;
  cursor: pointer;
  .img-item {
    margin-right: 20px;
    img {
      width: 100px;
      height: 100px;
    }
  }
}
.avatar-uploader-icon {
  position: relative;
  display: inline-block;

  font-size: 30px;
  color: #b4b4b4;
}
</style>