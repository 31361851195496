<!--
 * @Author: your name
 * @Date: 2021-01-12 11:12:02
 * @LastEditTime: 2021-01-14 14:02:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \scweb\src\components\uploadFile.vue
-->
<template>
  <div>
    <el-upload name="mfile" :headers="myHeaders" :action="uploadFileUrl" :show-file-list="false" :file-list="fileList" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" multiple list-type="picture">
      <div class="uploader-btn">
        <i class="el-icon-plus avatar-uploader-icon"></i>
      </div>
      <span style="color:red">*支持JPG、JPEG、PNG、SVG格式，最大5M</span>
    </el-upload>
    <!-- <el-upload v-if="value.length < limit" :disabled="disabled" ref="upload" :accept="accept" name="mfile" :action="url" :multiple="limit > 1" :before-remove="handleTip" :show-file-list="false" :on-remove="handleRemove" :on-success="handleSuccess" :headers="headers" :on-progress="onProgress" :on-error="onError" :limit="limit">
      <span slot="tip" style="margin-left:20px;color:red" v-if="isShow">(*只允许上传Excel文件)</span>
      <el-button :disabled="disabled" class="el-icon-upload" size="small" type="primary" v-if="!disabled">点击上传</el-button>
    </el-upload> -->
    <div v-if="imageUrl" class="img-uploader">
      <img :src="imageUrl" class="avatar">
    </div>
    <!-- <i v-if="imageUrl && isDel" style="margin:10px 10px 0;font-size:25px;color:red;cursor: pointer;" @click="deleteImg" class="el-icon-delete"></i> -->
  </div>

</template>
<script>
import { baseURL } from '@/utils/http';
import { getCookies } from '@/utils/utils'

export default {
  name: 'uploadFile',
  props: ['imageUrl', 'isDel'],
  data() {
    return {
      myHeaders: { 'x-token': getCookies('x-token') },     //设置上传文件请求头
      uploadFileUrl: baseURL + '/uploadFile/baiduUploadFile',
      fileList: []
    }
  },
  created() {
    this.$nextTick(() => {
      console.log(this.imageUrl);

      this.fileList = this.imageUrl
      console.log(this.fileList);

    })

  },
  methods: {
    deleteImg() {
      this.$emit('update:imageUrl', '');
    },
    handleAvatarSuccess(res, file) {
      console.log(file);
      console.log(res);
      console.log(this.fileList);

      if (res.code == 200) {
        this.$emit("input", [...this.imageUrl, { name: file.name, url: res.data }]);
        // this.fileList.push({ name: file.name, url: res.data })
        // this.$emit('update:imageUrl', res.data);
      } else {
        this.$message.error('上传错误，请稍后重试');
      }

    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/svg';
      const isLt5M = file.size / 5120 / 5120 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/JPEG/PNG/SVG 格式!');
      }
      if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      return isJPG && isLt5M;
    },
  }
}
</script>
<style scoped>
.avatar {
  float: left;
  max-height: 120px;
  min-height: 80px;
  min-width: 100px;
}
.uploader-btn {
  position: relative;
  border: 1px solid #b4b4b4;
  width: 100px;
  height: 100px;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 35px;
  cursor: pointer;
}
.img-uploader {
  position: relative;
  border: 1px solid #b4b4b4;
  max-width: 120px;
  max-height: 120px;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
}
.avatar-uploader-icon {
  position: relative;
  display: inline-block;

  font-size: 30px;
  color: #b4b4b4;
}
</style>